/**
 * A Base64 encoded string.
 */
export type Base64Str = string

/**
 * Base64 library that properly handles encoding/decoding Base64 on either node
 * or the browser.
 */
export namespace Base64 {
  // https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
  //
  // https://stackabuse.com/encoding-and-decoding-base64-strings-in-node-js/

  export function encode(data: string): string {
    if (typeof btoa !== 'undefined') {
      return btoa(data)
    } else {
      const buff = new Buffer(data)
      return buff.toString('base64')
    }
  }

  export function decode(data: Base64Str): string {
    if (typeof atob !== 'undefined') {
      return atob(data)
    } else {
      return Buffer.from(data, 'base64').toString()
    }
  }
}
