/* eslint-disable @typescript-eslint/require-await */
import { DateTime } from 'luxon'
import { GetServerSideProps, InferGetServerSidePropsType } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { Fragment, useCallback, useEffect, useMemo } from 'react'
import { useClient } from 'urql'
import JotaiFormProvider from '../../components/state/jotaiFormProvider'
import useJotaiForm, { JotaiForm } from '../../components/state/useJotaiForm'
import {
  AuthMethod,
  GetUserAuthInfoDocument,
  GetUserAuthInfoQuery,
  useEmailLinkForLoginMutation,
  useGetIsUserLoggedInQuery,
  UserAuthInfo,
} from '../../generated/graphql'
import LoginForm, { LoginCardType, LoginFormAtomType } from '../../pageComponents/login/loginForm'
import { Base64 } from '../../util/Base64'
import env from '../../util/env'
import buildLogger from '../../util/logger'
import { useErrorHandler } from '../../components/ErrorHandler/ErrorHandler'
import { useBillyRouter } from '../../components/route/useBillyRouter'

const logger = buildLogger('LoginPage')

function buildPasswordLoginUrl(userAuthInfo: UserAuthInfo): string {
  if (!userAuthInfo.clientId || !userAuthInfo.domainName || !userAuthInfo.userPoolId) {
    logger.warn({ userAuthInfo })
    throw new Error('UserAuthInfo is missing values')
  }
  const origin = window.location.origin
  const loginUrl = new URL(`${origin}/api/auth/signin-userpass`)
  loginUrl.searchParams.append('state', Base64.encode(JSON.stringify(userAuthInfo)))
  logger.debug({ msg: 'Redirecting to', loginUrl: loginUrl.toString() })
  return loginUrl.toString()
}

function redirectToForgotPasswordCognito(email: string, userAuthInfo: UserAuthInfo): void {
  const cognitoRegion = env.NEXT_PUBLIC_COGNITO_REGION
  const origin = window.location.origin
  const loginUrl = new URL(`https://${userAuthInfo.domainName}.auth.${cognitoRegion}.amazoncognito.com/forgotPassword`)
  loginUrl.searchParams.append('response_type', 'code')
  loginUrl.searchParams.append('client_id', userAuthInfo.clientId)
  loginUrl.searchParams.append('state', Base64.encode(JSON.stringify(userAuthInfo)))
  loginUrl.searchParams.append('redirect_uri', `${origin}/api/auth/signin`)
  loginUrl.searchParams.append('username', email)
  logger.debug({ msg: 'Redirecting to', loginUrl: loginUrl.toString() })
  window.location.assign(loginUrl.toString())
}

function redirectToCognitoLogin(email: string, userAuthInfo: UserAuthInfo): void {
  const cognitoRegion = env.NEXT_PUBLIC_COGNITO_REGION
  const origin = window.location.origin
  const loginUrl = new URL(
    `https://${userAuthInfo.domainName}.auth.${cognitoRegion}.amazoncognito.com/oauth2/authorize`
  )
  loginUrl.searchParams.append('response_type', 'code')
  loginUrl.searchParams.append('client_id', userAuthInfo.clientId)
  loginUrl.searchParams.append('state', Base64.encode(JSON.stringify(userAuthInfo)))
  loginUrl.searchParams.append('redirect_uri', `${origin}/api/auth/signin`)
  loginUrl.searchParams.append('idp_identifier', 'DefaultIDP')
  logger.debug({ msg: 'Redirecting to', loginUrl: loginUrl.toString() })
  window.location.assign(loginUrl.toString())
}

export function useLoginJotaiForm(): { emailQs: string; jotaiForm: JotaiForm<LoginFormAtomType> } {
  const router = useRouter()
  const emailQs = router.query.email as string
  const badPasswordQs = router.query.badPassword as string
  const unknownErrorQs = router.query.unknownError as string

  const jotaiForm = useJotaiForm<LoginFormAtomType>(
    useMemo(
      () => ({
        defaultValue: {
          badPassword: !!badPasswordQs,
          email: emailQs || '',
          password: '',
          showCard: emailQs ? LoginCardType.Password : LoginCardType.Email,
          message: '',
          skipTransition: true,
          unknownError: !!unknownErrorQs,
          yupErrors: {},
        },
        onSet: (oldValue, newValue, draft) => {
          if (oldValue.email !== newValue.email) {
            draft.getUserAuthInfoError = undefined
            draft.yupErrors.email = ''
          }
          if (oldValue.password !== newValue.password) {
            draft.badPassword = false
          }
        },
      }),
      [badPasswordQs, emailQs, unknownErrorQs]
    )
  )
  return { emailQs, jotaiForm }
}

function LoginPage({ currentYear }: InferGetServerSidePropsType<typeof getServerSideProps>): JSX.Element {
  const billyRouter = useBillyRouter()
  const [userLoggedIn] = useGetIsUserLoggedInQuery()
  const { query } = billyRouter

  useEffect(() => {
    if (userLoggedIn.data?.userLoggedIn && !query.logout) {
      billyRouter.push('/')
    }
  }, [billyRouter, query, userLoggedIn.data])

  if (userLoggedIn.fetching || userLoggedIn.data?.userLoggedIn) return <Fragment />

  return <LoginPageForm currentYear={currentYear} />
}

function LoginPageForm({ currentYear }: InferGetServerSidePropsType<typeof getServerSideProps>): JSX.Element {
  const { emailQs, jotaiForm } = useLoginJotaiForm()

  const urqlClient = useClient()
  const errorHandler = useErrorHandler()

  const submitEmail = useCallback(
    (email: string): void => {
      logger.debug({ msg: 'submitting' })
      urqlClient
        .query<GetUserAuthInfoQuery>(GetUserAuthInfoDocument, { email })
        .toPromise()
        .then((result) => {
          logger.trace({ result })

          if (result.error && result.error.message) {
            jotaiForm.set((form) => {
              form.getUserAuthInfoError = result.error as any
            })
            return
          }

          const userAuthInfo = result.data?.userAuthInfo
          if (userAuthInfo) {
            jotaiForm.set((form) => {
              form.userAuthInfo = userAuthInfo
            })

            if (userAuthInfo.authMethod === AuthMethod.SsoOnly) {
              redirectToCognitoLogin(email, userAuthInfo)
            } else {
              jotaiForm.set((form) => {
                form.showCard = LoginCardType.Password
              })
            }
          } else {
            jotaiForm.set((form) => {
              form.yupErrors.email = "Sorry, that isn't a registered email"
            })
          }
        })
        .catch(errorHandler)
    },
    [jotaiForm, urqlClient]
  )

  const [, emailLinkForLogin] = useEmailLinkForLoginMutation()

  const sendEmailLink = useCallback(
    (email: string) => {
      emailLinkForLogin({ email })
        .then((response) => {
          if (response.data?.emailLinkForLogin) {
            jotaiForm.set((form) => {
              form.message = 'Email link sent!'
              form.showCard = LoginCardType.Message
            })
          } else {
            jotaiForm.set((form) => {
              form.message = 'Oops.. error sending email link!'
              form.showCard = LoginCardType.Message
            })
          }
        })
        .catch(errorHandler)
    },
    [emailLinkForLogin, errorHandler, jotaiForm]
  )

  useEffect(() => {
    if (emailQs) {
      submitEmail(emailQs)
    }
  }, [emailQs, submitEmail])

  return (
    <>
      <Head>
        <title>Login | Subskribe</title>
      </Head>
      <JotaiFormProvider form={jotaiForm}>
        <LoginForm
          buildPasswordLoginUrl={buildPasswordLoginUrl}
          redirectToCognitoLogin={redirectToCognitoLogin}
          redirectToForgotPasswordCognito={redirectToForgotPasswordCognito}
          submitEmail={submitEmail}
          sendEmailLink={sendEmailLink}
          currentYear={currentYear}
        />
      </JotaiFormProvider>
    </>
  )
}
export const getServerSideProps: GetServerSideProps = async (context) => {
  const currentYear = DateTime.now().year.toString()
  const isLoggedOut = context.query?.logout
  if (isLoggedOut) {
    context.res.setHeader('Cache-Control', 'no-cache')
  }
  return {
    props: {
      currentYear,
    },
  }
}

export default LoginPage
